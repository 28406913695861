<template>
  <div>
    <Pane :showRemarks="false" :bottom="0">
      <a-tabs
        v-model="active"
        :tabBarStyle="{
          margin: '0',
        }"
      >
        <a-tab-pane key="1" tab="部门绩效"> </a-tab-pane>
        <a-tab-pane key="2" tab="员工绩效"> </a-tab-pane>
      </a-tabs>
    </Pane>

    <a-card class="container">
      <Dept v-if="active === '1'" />
      <User v-if="active === '2'" />
    </a-card>
  </div>
</template>

<script>
import Dept from "./components/dept.vue";
import User from "./components/user.vue";
export default {
  components: {
    Dept,
    User,
  },
  data() {
    return {
      active: "1",
    };
  },
};
</script>