<template>
  <div>
    <a-form-model layout="inline">
      <a-form-model-item>
        <a-date-picker
          mode="year"
          placeholder="年份"
          @panelChange="panelChange"
          :value="year"
          format="YYYY"
          :open="open"
          @openChange="openChange"
          style="width: 80px"
          :allowClear="false"
        />
      </a-form-model-item>

      <a-form-model-item>
        <a-select
          show-search
          :filter-option="$selectFilterOption"
          placeholder="部门"
          v-model="dept"
          style="width: 160px"
          :allowClear="true"
        >
          <a-select-option
            v-for="item in organizationList"
            :key="item.id"
            :value="item.uniqueName"
            >{{ item.uniqueName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
    <Padding />

    <a-table
      bordered
      :dataSource="filteredList"
      :loading="loading"
      :pagination="false"
      rowKey="id"
    >
      <a-table-column title="部门" data-index="uniqueName"></a-table-column>
      <a-table-column
        :title="year.format('YYYY') + '年绩效成绩'"
        align="center"
      >
        <template slot-scope="text">
          <span style="color: #1890ff">
            {{ text.score ? text.score : "--" }}
          </span>
        </template>
      </a-table-column>
      <a-table-column
        :title="year.format('YYYY') + '年绩效等级'"
        align="center"
      >
        <template slot-scope="text">
          <span style="color: #1890ff" v-if="typeof text.score === 'number'">
            <span v-if="text.score > 95"> A+ </span>
            <span v-else-if="text.score >= 90"> A </span>
            <span v-else-if="text.score >= 80"> B </span>
            <span v-else-if="text.score >= 70"> C </span>
            <span v-else-if="text.score < 70"> D/E </span>
          </span>
          <span v-else>--</span>
        </template>
      </a-table-column>

      <a-table-column key="control" title="操作" align="center">
        <template slot-scope="text">
          <a-space>
            <a
              href="#"
              v-if="$getPermission($route.path + '/edit')"
              @click.prevent="addScore(text)"
            >
              打分
            </a>
          </a-space>
        </template>
      </a-table-column>
    </a-table>

    <a-modal :visible="visible" title="打分" :footer="null" @click="cancel">
      <a-input-number :min="0" :max="100" v-model="score" />
      <div class="right">
        <a-space>
          <a-button @click="cancel">取消</a-button>
          <a-button type="primary" @click="save">保存</a-button>
        </a-space>
      </div>
    </a-modal>
  </div>
</template>

<script>
import organization from "@/mixins/organization";
import moment from "moment";
import request from "@/api/request";
export default {
  mixins: [organization],

  data() {
    return {
      year: moment(),
      open: false,

      dept: undefined,

      loading: false,
      list: [],

      editingText: {},
      visible: false,
      score: 0,
    };
  },

  computed: {
    scoreList() {
      const list = [];
      this.organizationList.forEach((item) => {
        const element = this.list.find((element) => element.pid === item.id);
        list.push({
          ...item,
          score: element ? element.result : null,
          scoreId: element ? element.id : null,
        });
      });
      return list;
    },
    filteredList() {
      if (this.dept) {
        return this.scoreList.filter(
          (item) => item.uniqueName && item.uniqueName.indexOf(this.dept) > -1
        );
      } else {
        return this.scoreList;
      }
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    // 年份选择器
    panelChange(value) {
      this.year = value;
      this.open = false;
      this.getList();
    },
    // 年份选择器面板显示隐藏
    openChange(value) {
      this.open = value;
    },

    getList() {
      this.loading = true;
      request({
        url: "/user-service/perfAppraise/list",
        method: "post",
        data: {
          year: this.year.format("YYYY"),
          type: "org",
          pageNum: 1,
          pageSize: 1000,
        },
      })
        .then((res) => {
          if (res && Array.isArray(res.list)) {
            this.list = res.list;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addScore(text) {
      this.editingText = text;
      this.visible = true;
    },
    cancel() {
      this.score = 0;
      this.visible = false;
    },
    save() {
      if (typeof this.score === "number") {
        if (this.editingText.scoreId) {
          request({
            url: "/user-service/perfAppraise/modify",
            method: "post",
            data: {
              id: this.editingText.scoreId,
              pid: this.editingText.id,
              result: this.score,
              type: "org",
              year: this.year.format("YYYY"),
            },
          }).then(() => {
            this.getList();
          });
        } else {
          request({
            url: "/user-service/perfAppraise/save",
            method: "post",
            data: {
              pid: this.editingText.id,
              result: this.score,
              type: "org",
              year: this.year.format("YYYY"),
            },
          }).then(() => {
            this.getList();
          });
        }

        this.cancel();
      } else {
        this.$message.error("请输入正确的分数");
      }
    },
  },
};
</script>